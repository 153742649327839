<i18n>
{
  "ru": {
    "title": "Подрядчики"
  },
  "he": {
    "title": "קבלן"
  }
}
</i18n>

<template>
  <div>
    <h1 class="text-h4 mb-2">{{ $t('title') }}</h1>

    <v-row class="align-center mb-5">
      <template v-for="(item, index) in filter">
        <v-col
          cols="3"
          :key="`filter-item-${index}`"
        >
          <template if="item.type === 'select'">
            <v-select
              v-model="item.value"
              :items="item.list"
              item-text="name"
              item-value="id"
              :label="item.name"
              color="teal"
              clearable
              @change="changeFilter()"
            ></v-select>
          </template>
        </v-col>
      </template>

      <v-col
        cols="auto ms-auto"
      >
        <v-btn
          color="teal"
          dark
          to="/contractors/add"
          v-if="$store.getters.isAdmin"
        >{{ $t('btn.add') }}</v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="10"
      class="elevation-1"
      :footer-props="footer"
      :server-items-length="total"
      :page.sync="page"
      :loading="loading"
    >
      <template v-slot:item.UF_ACTIVE="{ item }">
        {{ (item.UF_ACTIVE === '1') ? $t('yes') : $t('no') }}
      </template>

      <template v-slot:item.btn="{ item }">
        <v-menu
          bottom
          v-if="$store.getters.isAdmin"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              dark
              icon
              color="teal"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon dark>
                mdi-dots-vertical
              </v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              :to="`/contractors/${item.ID}`"
            >
              <v-list-item-title>
                {{ $t('btn.detail') }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'ContractorsList',
  data: (vm) => ({
    items: [],
    filter: [
      {
        name: vm.$t('props.UF_ACTIVE'),
        code: 'UF_ACTIVE',
        value: null,
        type: 'select',
        list: [
          {
            id: '1',
            name: vm.$t('yes'),
          },
          {
            id: '0',
            name: vm.$t('no'),
          },
        ],
      },
    ],
    headers: [
      {
        text: '№',
        value: 'ID',
        sortable: false,
      },
      {
        text: vm.$t('props.UF_NAME'),
        value: 'UF_NAME',
        sortable: false,
      },
      {
        text: vm.$t('props.UF_ACTIVE'),
        value: 'UF_ACTIVE',
        sortable: false,
      },
      {
        text: '',
        value: 'btn',
        align: 'right',
        sortable: false,
      },
    ],
    footer: {
      'disable-items-per-page': true,
      'items-per-page-options': [50, 100],
    },
    page: 1,
    limit: 50,
    total: 0,
    loading: false,
  }),
  watch: {
    page() {
      this.getContractors();
    },
  },
  computed: {
    paramsFilter() {
      const params = {};
      const filterProps = this.filter.filter((item) => item.value || item.value === false);

      filterProps.forEach((item) => {
        params[item.code] = item.value;
      });

      return params;
    },
  },
  methods: {
    changeFilter() {
      this.page = 1;
      this.getContractors();
    },
    getContractors() {
      this.loading = true;

      const params = {
        order: {
          UF_NAME: 'ASC',
        },
        filter: this.paramsFilter,
        select: ['*'],
        limit: this.limit,
        offset: (this.page - 1) * this.limit,
      };

      this.$store.dispatch('getContractors', { params }).then((response) => {
        this.items = response.data.data.result;
        this.total = response.data.data.count;

        this.loading = false;
      });
    },
  },
  created() {
    this.getContractors();
  },
};
</script>
